import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const ImageTextContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: var(--background-gray);

    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
    };
`;

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    img {
        flex: 1;
        height: 100%;
    }

    @media screen and (max-width: ${sizes.mobileL}) {
        max-height: 390px;
        img {
            width: 100%;
        }
    };
`;

const TextContainer = styled.div`
    padding: 12em 12em 6.4em 6.4em;
    max-width: 656px;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6.4em;
    };

    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 3.2em 2.4em;
    };
`;

const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    gap: 3.2em;
`;

const MainHeader = styled.h1`
    line-height: 1em;
    font-size: 4rem;
    max-width: 75%;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.2em;
        max-width: 85%;
    };
`;

const SubHeader = styled.p`
    font-size: 2rem;
    font-weight: 400;
`;

const ImageText = ({ src, altText, mainHeader, subHeader1, subHeader2 }) => {
    return (
        <ImageTextContainer>
            <ImageWrapper>
                <img src={src} alt={altText} />
            </ImageWrapper>
            <TextContainer>
                <HeaderContainer>
                    <MainHeader>
                        {mainHeader}
                    </MainHeader>
                    <SubHeader>
                        {subHeader1}
                    </SubHeader>
                    <SubHeader>
                        {subHeader2}
                    </SubHeader>
                </HeaderContainer>
            </TextContainer>
        </ImageTextContainer>
    )
}

export default ImageText;