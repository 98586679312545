import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const BannerContainer = styled.section`
    background-color: var(--support-yellow);
`;

const BannerContentsWrapper = styled.div`
    align-items: center;
    max-width: 1440px;
    margin: auto;
    display: flex;
    gap: 4.8em;
    padding: 6.4em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 0;
        display: block;
    };
`;

const MainHeader = styled.h2`
    font-size: 4.8rem;
    position: relative;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 2rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6em;
    padding: 0 6rem;
    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6rem;
    }
`;

const VideoColumns = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 6rem 6rem 6rem;
    @media screen and (max-width: ${sizes.laptop}) {
        padding: 0;
        flex-direction: column;
    }
`;

const VideoSection = ({ children, headerString, subHeaderString }) => {
  return (
    <BannerContainer>
      <BannerContentsWrapper>
        <HeaderContainer>
            <MainHeader>
              {headerString}
            </MainHeader>
            <SubHeader>
              {subHeaderString}
            </SubHeader>
        </HeaderContainer>
      </BannerContentsWrapper>
      <VideoColumns>
        {children}
      </VideoColumns>
    </BannerContainer>
    )
}

export default VideoSection;