import React from 'react';
import APPLICATION_CONSTANTS from './constants/strings';
import Hero from './shared/Hero';
import SimpleBanner from './shared/SimpleBanner';
import CTABanner from './shared/CTABanner';
import GradientBanner from './shared/GradientBanner';
import Protections from './shared/Protections';
import Testimonials from './shared/Testimonials';
import { devices } from './constants/devices';
import { useMediaQuery } from 'react-responsive';
import Video from './shared/Video';
import Steps from './shared/Steps';

const Homeowners = () => {
    const isTablet = useMediaQuery({ query: devices.tablet });

    return (
        <div>
            <Hero 
                mainHeader={APPLICATION_CONSTANTS.HOMEOWNERS_HERO.MAIN_HEADER}
                subHeader={APPLICATION_CONSTANTS.HOMEOWNERS_HERO.SUB_HEADER}
                src={'/images/homeowners-hero.png'}
                altText={'a family smiling together'}
            />
            <SimpleBanner string={APPLICATION_CONSTANTS.HOMEOWNERS_SIMPLE_BANNER} />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.HOMEOWNERS_GRADIENT_BANNER.HEADER}
                subHeaderString={APPLICATION_CONSTANTS.HOMEOWNERS_GRADIENT_BANNER.SUB_HEADER}
                cardStrings={APPLICATION_CONSTANTS.HOMEOWNERS_GRADIENT_BANNER.CARDS}
                type={"large"}
            />
            <Protections strings={APPLICATION_CONSTANTS.HOMEOWNERS_PROTECTIONS_BANNER} />
            <CTABanner type={APPLICATION_CONSTANTS.LEARN_MORE} strings={APPLICATION_CONSTANTS.HOMEOWNERS_CTA_BANNER} />
            <GradientBanner 
                headerString={APPLICATION_CONSTANTS.HOMEOWNERS_GRADIENT_BANNER2.HEADER}
                cardStrings={APPLICATION_CONSTANTS.HOMEOWNERS_GRADIENT_BANNER2.CARDS}
                type={"thin"}
            />
            <Steps 
                headerString={APPLICATION_CONSTANTS.HOMEOWNERS_STEPS_BANNER.MAIN_HEADER}
                steps={APPLICATION_CONSTANTS.HOMEOWNERS_STEPS_BANNER.STEPS}
            />
            <Testimonials strings={APPLICATION_CONSTANTS.HOMEOWNERS_TESTIMONIALS} />
            {isTablet && <Video src={"https://www.youtube.com/embed/VJCxjY6mvuw?si=zlkzdE-KCyfwe8BH"} />}
            <CTABanner strings={APPLICATION_CONSTANTS.HOMEOWNERS_CTA_BANNER2} />
        </div>
    );
};

export default Homeowners;