import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const VideoWrapper = styled.div`
    position: relative;
    padding: 56.25% 0 0 0;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    margin: 0;

    @media screen and (max-width: ${sizes.mobileL}) {
        position: relative;
        top: 0;
    };
`;

const EmbeddedVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: ${sizes.mobileL}) {
        bottom: 0;
    };
`;

const Video = ({ src }) => {  
    return (
        <VideoWrapper>
            <EmbeddedVideo 
                src={src}
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        </VideoWrapper>
    );
};

export default Video;