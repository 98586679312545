import React, { useState, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import APPLICATION_CONSTANTS from '../constants/strings';
import { sizes } from '../constants/devices';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const BannerContainer = styled.section`
    padding: 12em;
    display: flex;
    flex-direction: column;
    gap: 4.8em;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4;
        background-image: url('/images/sunrise-background.svg');
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6em;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        gap: 4em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
        gap: 3.2em;
    };
`;

const MainHeader = styled.h2`
    font-size: 4.8rem;
    position: relative;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const SubHeader = styled.p`
    font-size: 2rem;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6em;
    padding: 0em calc((100vw - 1680px) /2);

    @media screen and (min-width: ${sizes.desktop}) {
        padding: 0em 12em;
    };
`;

const SelectContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2.4em;
    padding: 0em calc((100vw - 1680px) /2);

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(2, 1fr);
    };
    @media screen and (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 3.2em;
        padding-bottom: 3.2em;
        border-bottom: 1px solid black;
    };
    @media screen and (min-width: ${sizes.desktop}) {
        padding: 0em 12em;
    };
`;

const SelectWrapper = styled.div`
    .MuiInputBase-root {
        border-radius: 8px;
    }
    .MuiSelect-select {
        background-color: white;
        border-radius: 8px !important;
        padding-top: 0.7em;
        position: relative;
    }
    .MuiSelect-icon {
        position: absolute;
        top: 0;
        width: 1.4em;
        height: 100%;
        color: black;
    }
`;

const PositionsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    row-gap: 3em;
    column-gap: 2.4em;
    max-width: 1440px;
    margin: auto;
    width: 100%;

    @media screen and (max-width: ${sizes.tablet}) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(5, 1fr);
    };
    @media screen and (max-width: 560px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(9, 1fr);
    };
`;

const Position = styled.div`
    padding: 3.2em 2.4em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1.2em;
    position: relative;

    &:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.6;
        background-color: white;
        border-radius: 32px;
    };
`;

const PositionTextWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${props => props.$isHeader ? '1.2em' : '2.4em'};
`;

const JobTitle = styled.h4`
    font-size: 2.4rem;
    margin: 0;
    max-width: 70%;
    @media screen and (max-width: ${sizes.tablet}) {
        max-width: 90%;
        font-size: 2rem;
    };
    @media screen and (max-width: 560px) {
        max-width: 80%;
    };
`;
const Location = styled.p`
    font-size: 2rem;
`;

const Link = styled.a`
    font-size: 1.6rem;
    border-bottom: 2px solid black;
    padding: 0.1em 0em;
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    letter-spacing: .1rem;
    text-decoration: none;
    color: black;

    &:hover {
        color: var(--oncourse-blue);
        border-bottom: 2px solid var(--oncourse-blue);
    }
`;

const LinkWrapper = styled.div``;

const OpenPositions = ({ headerString, subHeaderString, openPositions }) => {
    const ALL_LOCATIONS = 'All locations';

    const [location, setLocation] = useState(ALL_LOCATIONS);
    const [visiblePositions, setVisiblePositions] = useState([]);

    const openPositionsRef = useRef(null);

    useEffect(() => {
        const locInitialState = location === ALL_LOCATIONS;

        if(locInitialState) {
            setVisiblePositions(openPositions);
            return;
        };

        const newState = openPositions.filter(position => JSON.stringify(position).includes(location));

        setVisiblePositions(newState);
      }, [openPositions, location]);

    const handleLocChange = (e) => {
        setLocation(e.target.value);
    }

    const locationOptions = ['Mt Laurel', 'Naperville'];

    const selectStrings = [
        {
            HEADER: 'Select location',
            PLACEHOLDER: 'All locations',
            OPTIONS: locationOptions.sort(),
        },
    ]

    const selects = selectStrings.map(select => {
        const options = select.OPTIONS.map(option => {
            return (
                <MenuItem key={option} style={{ fontSize: '1.6rem'}} value={option}>
                    {option}
                </MenuItem>
            );
        });

        const placeholder = (
            <em style={{ fontSize: '1.6rem', fontStyle: 'normal'}}>
                {select.PLACEHOLDER}
            </em>
        );

        const selectCurrentValue = location;
        const selectInitialState = ALL_LOCATIONS;

        const labelStyles = { 
            paddingBottom: '0.6em', 
            color: 'black',
            fontWeight: '700',
            fontSize: '1.4rem'
        };

        const selectID = select.HEADER.split(' ')[1];
        const labelID = select.HEADER.split(' ')[1].concat("-label");

        return (
            <SelectWrapper key={select.HEADER}>
                <InputLabel style={labelStyles} id={labelID}>
                    {select.HEADER}
                </InputLabel>
                <Select
                    labelId={labelID}
                    id={selectID}
                    value={selectCurrentValue}
                    size="small"
                    onChange={handleLocChange}
                    sx={{ width: '100%', fontSize: '1.6rem' }}
                    renderValue={() => selectCurrentValue}
                    IconComponent={ExpandMoreIcon}
                >
                    <MenuItem disabled value="">
                        {placeholder}
                    </MenuItem>
                    <MenuItem style={{ fontSize: '1.6rem' }} value={selectInitialState}>
                        <em>None</em>
                    </MenuItem>
                    {options}
                </Select>
            </SelectWrapper>
        );
    });

    const positions = visiblePositions.map((position, index) => {
        const LEARN_MORE = APPLICATION_CONSTANTS.CAREERS_OPEN_POSITIONS.LEARN_MORE;
        const title = position.Title;
        const locations = position.Locations;
        const url = 'https://oncoursehome.rec.pro.ukg.net/AME1507ARCW/JobBoard/91a0afbb-1e7d-4b17-8f53-b261bff79276/OpportunityDetail?opportunityId=' + position.Id;

        return (
            <Position key={`position-${index}`}>
                <PositionTextWrapper $isHeader>
                    <JobTitle>
                        {title}
                    </JobTitle>
                </PositionTextWrapper>
                <PositionTextWrapper>
                    <Location style={{ fontSize: '1.6rem'}}>
                        {locations.map((location) => {
                            return (
                                <span>
                                    {location.LocalizedDescription}<br />
                                </span>
                            );
                        })}
                    </Location>
                    <LinkWrapper>
                        <Link href={url} target='_blank'>
                            {LEARN_MORE}
                        </Link>
                    </LinkWrapper>
                </PositionTextWrapper>
            </Position>
        );
    });

    return (
        <BannerContainer ref={openPositionsRef}>
            <HeaderContainer>
                <MainHeader>
                    WE ARE HIRING
                </MainHeader>
                <SubHeader>
                    If you’re ready for a job where you can make a difference, you’re in the right place. Whether you’re an experienced professional or just starting your career, there’s a place for you at Oncourse Home Solutions.  
                </SubHeader>
                <SubHeader>
                    We offer our SUPERs competitive pay, comprehensive health and life benefits, vacation time, a culture where people can thrive, and much more. Take the next step and embark on a rewarding career with us. 
                </SubHeader>
                <h2 style={{fontSize: '4rem', marginTop: '1em'}}>Open Positions</h2>
            </HeaderContainer>
            <SelectContainer>
                {selects}
            </SelectContainer>
            <PositionsContainer>
                {positions}
            </PositionsContainer>
        </BannerContainer>
    );
};

export default OpenPositions;