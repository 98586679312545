import React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';

const BannerContainer = styled.section`
    background-color: ${props => props.type === "quote" ? 'var(--green)' : 'var(--support-yellow)'};
`;

const BannerStringWrapper = styled.div`
    max-width: 1440px;
    margin: auto;
    padding: 10em 12em;
    display: flex;
    flex-direction: column;
    gap: 4.8em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 5em 6em;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 4.8em 2.4em;
    };
`;

const BannerString = styled.p`
    font-size: 3.6rem;
    max-width: 1250px;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 3.2rem;
    };
    @media screen and (max-width: ${sizes.tablet}) {
        font-size: ${props => props.type === "quote" ? '2.4rem' : '2.8rem'};
    };
`;

const NameTitleStringContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NameString = styled.p`
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 0.4em;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 1.6rem;
    };
`;

const TitleString = styled.p`
    font-size: 1.6rem;
`;

const SimpleBanner = ({ type, string, string2 }) => {
    const banner = (
        <BannerStringWrapper>
            <BannerString>
                {string}
            </BannerString>
            {string2 && <BannerString>
                {string2}
            </BannerString>}
        </BannerStringWrapper>
    );

    const quoteBanner = (
        <BannerStringWrapper>
            <BannerString type={type}>
                {string.CONTENT}
            </BannerString>
            <NameTitleStringContainer>
                <NameString>
                    {string.NAME}
                </NameString>
                <TitleString>
                    {string.TITLE}
                </TitleString>
            </NameTitleStringContainer>
        </BannerStringWrapper>
    );
    return (
        <BannerContainer type={type}>
            {type === "quote" ? quoteBanner : banner }
        </BannerContainer>
    );
};

export default SimpleBanner;