import * as React from 'react';
import { styled } from 'styled-components';
import Dialog from '@mui/material/Dialog';
import Video from './Video';

const DialogVideo = styled.div`
    height: 100%;
    width: 100%;
`;

const Link = styled.a`
    font-size: 1.6rem;
    border-bottom: 2px solid black;
    padding: 0.1em 0em;
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    letter-spacing: .1rem;
    text-decoration: none;
    color: black;

    &:hover {
        color: var(--oncourse-blue);
        border-bottom: 2px solid var(--oncourse-blue);
        cursor: pointer;
    };
`;

const LinkWrapper = styled.div``;

const VideoModalLink = ({ videoSrc, linkText }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <LinkWrapper>
        <Link onClick={handleClickOpen}>
            { linkText || 'Watch Video' }
        </Link>
      </LinkWrapper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogVideo id="DialogVideo">
          <Video src={videoSrc} />
        </DialogVideo>
      </Dialog>
    </React.Fragment>
  );
};

export default VideoModalLink;
