import * as React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import Dialog from '@mui/material/Dialog';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Video from './Video';

const BannerContainer = styled.section`
    width: 33.3%;
    display: inline-block;
    background-color: var(--support-yellow);

    @media screen and (max-width: ${sizes.laptop}) {
        width: 100%;
        display: block;
    };
`;

const BannerContentsWrapper = styled.div`
    padding: 2.4em;

    @media screen and (max-width: ${sizes.laptop}) {
      padding: 0;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    color: var(--oncourse-blue);

    img {
        flex: 1;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    svg {
      font-size: 12rem;
      position: absolute;
    }

    @media screen and (max-width: ${sizes.mobileL}) {
        max-height: 390px;
        img {
            width: 100%;
        }
    };
`;

const DialogVideo = styled.div`
    height: 100%;
    width: 100%;
`;

const TextContainer = styled.div`
    flex: 1;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6.4em;
    };

    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 3.2em 2.4em;
    };
`;

const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    gap: 3.2em;
    margin-top: 2.4rem;
    @media screen and (max-width: ${sizes.laptop}) {
      margin-top: 0;
    }
`;

const MainHeader = styled.h1`
    line-height: 1em;
    font-size: 4rem;
    max-width: 75%;
    text-transform: uppercase;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.2em;
        max-width: 85%;
    };
`;

const SubHeader = styled.p`
    font-size: 2rem;
    font-weight: 400;
`;

const CTA = styled.a`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 48px;
    padding: 1.2rem 4rem;
    background-color: transparent;
    border: ${props => `2px solid ${props.color}`};
    color: ${props => props.color};
    cursor: pointer;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        background-color: ${props => props.color};
        color: ${props => props.color === "white" ? 'var(--oncourse-blue)' : 'white'};
    }
    a {
        color: ${props => props.color};
        text-decoration: none;
    }
`;

const VideoModal = ({ mainHeader, subHeader1, subHeader2, thumbSrc, altText, videoSrc, buttonText }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BannerContainer>
        <BannerContentsWrapper>
          <ImageWrapper onClick={handleClickOpen}>
            <img 
              src={thumbSrc} 
              alt={altText} 
            />
            <PlayCircleIcon />
          </ImageWrapper>
          <TextContainer>
            <HeaderContainer>
              <MainHeader>
                {mainHeader}
              </MainHeader>
              <SubHeader>
                {subHeader1}
              </SubHeader>
              <SubHeader>
                {subHeader2}
              </SubHeader>
            </HeaderContainer>
            <CTA 
                onClick={handleClickOpen} 
                color={'black'}
            >
                { buttonText || 'Watch Now' }
            </CTA>
          </TextContainer>
        </BannerContentsWrapper>
      </BannerContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'lg'}
      >
        <DialogVideo id="DialogVideo">
          <Video src={videoSrc} />
        </DialogVideo>
      </Dialog>
    </React.Fragment>
  );
};

export default VideoModal;
