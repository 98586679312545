import * as React from 'react';
import { styled } from 'styled-components';
import { sizes } from '../constants/devices';
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Dialog from '@mui/material/Dialog';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Video from './Video';
import 'swiper/css';
import 'swiper/css/navigation';

const SwiperBannerContainer = styled.section`
    background-color: var(--support-yellow);
`;

const SwiperBannerContentsWrapper = styled.div`
    align-items: center;
    max-width: 1440px;
    margin: auto;
    display: flex;
    padding: 0 6.4em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 0;
        display: block;
    };
`;

const SwiperMainHeader = styled.h2`
    font-size: 4.8rem;
    position: relative;

    @media screen and (max-width: ${sizes.laptop}) {
        font-size: 4rem;
    };
    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.4rem;
    };
`;

const SwiperSubHeader = styled.p`
    font-size: 2rem;
`;

const SwiperHeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6em;
    padding: 0 6rem;
    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6rem;
    }
`;

const BannerContainer = styled.section`
    background-color: var(--support-yellow);
`;

const BannerContentsWrapper = styled.div`
    align-items: center;
    max-width: 1440px;
    margin: auto;
    display: flex;
    gap: 4.8em;
    padding: 6.4em;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 0;
        display: block;
    };
`;

const ImageWrapper = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    color: var(--oncourse-blue);

    img {
        flex: 1;
        height: 100%;
        max-width: 100%;
        object-fit: contain;
    }

    svg {
      font-size: 12rem;
      position: absolute;
    }

    @media screen and (max-width: ${sizes.mobileL}) {
        max-height: 390px;
        img {
            width: 100%;
        }
    };
`;

const DialogVideo = styled.div`
    height: 100%;
    width: 100%;
`;

const TextContainer = styled.div`
    flex: 1;

    @media screen and (max-width: ${sizes.laptop}) {
        padding: 6.4em;
    };

    @media screen and (max-width: ${sizes.mobileL}) {
        padding: 3.2em 2.4em;
    };
`;

const HeaderContainer = styled.header`
    display: flex;
    flex-direction: column;
    gap: 3.2em;
    margin-top: 2.4rem;
    @media screen and (max-width: ${sizes.laptop}) {
      margin-top: 0;
    }
`;

const MainHeader = styled.h1`
    line-height: 1em;
    font-size: 4rem;
    max-width: 75%;
    text-transform: uppercase;

    @media screen and (max-width: ${sizes.mobileL}) {
        font-size: 3.2em;
        max-width: 85%;
    };
`;

const SubHeader = styled.p`
    font-size: 2rem;
    font-weight: 400;
`;

const CTA = styled.a`
    font-family: "neusa-next-std-compact", sans-serif;
    font-weight: 700;
    font-size: 1.6rem;
    border-radius: 48px;
    padding: 1.2rem 4rem;
    background-color: transparent;
    border: ${props => `2px solid ${props.color}`};
    color: ${props => props.color};
    cursor: pointer;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
        background-color: ${props => props.color};
        color: ${props => props.color === "white" ? 'var(--oncourse-blue)' : 'white'};
    }
    a {
        color: ${props => props.color};
        text-decoration: none;
    }
`;

const SwiperVideos = ({ headerString, subHeaderString, color, videos }) => {

  const SwiperVideoModal = ({video}) => {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      console.log('opening', video)
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    return(
      <React.Fragment>
        <BannerContainer>
          <BannerContentsWrapper>
            <ImageWrapper onClick={handleClickOpen}>
              <img 
                src={video.thumbSrc} 
                alt={video.altText} 
              />
              <PlayCircleIcon />
            </ImageWrapper>
            <TextContainer>
              <HeaderContainer>
                <MainHeader>
                  {video.mainHeader}
                </MainHeader>
                <SubHeader>
                  {video.subHeader1}
                </SubHeader>
                <SubHeader>
                  {video.subHeader2}
                </SubHeader>
              </HeaderContainer>
              <CTA 
                  onClick={handleClickOpen}
                  color={'black'}
              >
                  { video.buttonText || 'Watch Now' }
              </CTA>
            </TextContainer>
          </BannerContentsWrapper>
        </BannerContainer>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'lg'}
        >
          <DialogVideo id="DialogVideo">
            <Video src={video.videoSrc} />
          </DialogVideo>
        </Dialog>
      </React.Fragment>
    )
  }

  return (
    <>
      <SwiperBannerContainer>
        <SwiperBannerContentsWrapper>
          <SwiperHeaderContainer>
            <SwiperMainHeader>
              {headerString}
            </SwiperMainHeader>
            <SwiperSubHeader>
              {subHeaderString}
            </SwiperSubHeader>
          </SwiperHeaderContainer>
        </SwiperBannerContentsWrapper>
      </SwiperBannerContainer>
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        centeredSlides={true}
        className="mySwiper"
        modules={[Autoplay, Pagination, Navigation]}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        rewind={true}
      >
        {videos.map((video, index) => {
          return (
            <SwiperSlide key={index}>
              <SwiperVideoModal 
                video={video}
              />
            </SwiperSlide>
          )}
        )}
      </Swiper>
    </>
  );
}

export default SwiperVideos;